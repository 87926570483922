// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

$white   : #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black   : #000 !default;

$card-background:#242424;

$grays: () !default;
$grays: map-merge(("100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900),
  $grays);

$black      : #141414 !default;
$indigo     : #6610f2 !default;
$purple     : #6f42c1 !default;
$pink       : #e83e8c !default;
$red        : #dc3545 !default;
$orange     : #F78A0E !default;
$yellow     : #ffcc00 !default;
$green      : #00b369 !default;
$cyan       : #1a61be !default;
$field-label:#C5C5C5;
$field-error:#F70E0E;

$colors : () !default;
$colors : map-merge(("black": $black,
    "indigo": $indigo,
    "purple": $purple,
    "pink": $pink,
    "red": $red,
    "orange": $orange,
    "yellow": $yellow,
    "green": $green,
    "cyan": $cyan,
    "white": $white,
    "gray": $gray-600,
    "gray-dark": $gray-800,
    "field-label":$field-label,
    "field-error":$field-error,
  ),
  $colors);

$primary  : $orange !default;
$secondary: $black !default;
$success  : $green !default;
$info     : $cyan !default;
$warning  : $yellow !default;
$danger   : $red !default;
$light    : $gray-100 !default;
$dark     : $gray-800 !default;

$theme-colors: () !default;
$theme-colors: map-merge(("primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark),
  $theme-colors);

$custom-file-text: (en: "Browse",
  es: "Elegir"
);

$shadow-default: 0px 0px 10px 0px rgba(0, 0, 0, 0.20);

$brackpoint-lg: 1160px;
$brackpoint-md: 840px;
$brackpoint-sm: 420px;

@mixin mobile {
  @media (max-width:#{$brackpoint-md}) {
    @content;
  }
}

@mixin brackpoint-lg {
  @media screen and (max-width:#{$brackpoint-lg}) {
    @content;
  }
}

@mixin brackpoint-md {
  @media screen and (max-width:#{$brackpoint-md}) {
    @content;
  }
}

@mixin brackpoint-sm {
  @media screen and (max-width:#{$brackpoint-sm}) {
    @content;
  }
}